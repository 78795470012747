import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';
import SignInPrompt from '../components/SignInPrompt';
import SubscribePrompt from '../components/SubscribePrompt';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebaseClient';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

function Upload() {
  const navigate = useNavigate();
  const location = useLocation();
  const { type } = location.state || {};

  const { user, emailConfirmed, userData, incrementGenerationCount } = useAuth();

  const [imageDataUrl, setImageDataUrl] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState(null);

  const [isSignInPromptOpen, setIsSignInPromptOpen] = useState(false);
  const [isSubscribePromptOpen, setIsSubscribePromptOpen] = useState(false);

  const UNAUTH_GENERATE_LIMIT = 2;
  const AUTH_GENERATE_LIMIT = 20;

  const [generateCount, setGenerateCount] = useState(0);

  useEffect(() => {
    const initializeGenerateCount = async () => {
      const count = await getGenerateCount();
      setGenerateCount(count);
    };

    initializeGenerateCount();
  }, [user]);

  const getGenerateCount = async () => {
    if (user) {
      const userDocRef = doc(db, 'user_data', user.uid);
      const userDoc = await getDoc(userDocRef);
      return userDoc.exists() ? (userDoc.data().generation_count || 0) : 0;
    } else {
      return parseInt(localStorage.getItem('generateCount') || '0', 10);
    }
  };

  const updateGenerateCount = async () => {
    if (user) {
      const userDocRef = doc(db, 'user_data', user.uid);
      const userDoc = await getDoc(userDocRef);
      const currentCount = userDoc.exists() ? (userDoc.data().generation_count || 0) : 0;
      await updateDoc(userDocRef, { generation_count: currentCount + 1 });
      incrementGenerationCount();
    } else {
      const currentCount = parseInt(localStorage.getItem('generateCount') || '0', 10);
      localStorage.setItem('generateCount', (currentCount + 1).toString());
    }
    setGenerateCount(prev => prev + 1);
  };

  const EXTERIOR_STYLES = [
    { label: "Coastal", value: "Coastal" },
    { label: "Coastal Modern", value: "Coastal Modern" },
    { label: "Contemporary", value: "Contemporary" },
    { label: "Craftsman", value: "Craftsman" },
    { label: "Mid Century Modern", value: "Mid Century Modern" },
    { label: "Modern Farmhouse", value: "Modern Farmhouse" },
    { label: "Mountain Modern", value: "Mountain Modern" },
    { label: "Scandinavian", value: "Scandinavian" },
    { label: "Southwestern", value: "Southwestern" },
  ];

  const INTERIOR_STYLES = [
    { label: "Coastal", value: "Coastal" },
    { label: "Contemporary", value: "Contemporary" },
    { label: "Glam", value: "Glam" },
    { label: "Industrial", value: "Industrial" },
    { label: "Mediterranean", value: "Mediterranean" },
    { label: "Mid-Century Modern", value: "Mid-Century Modern" },
    { label: "Minimalist", value: "Minimalist" },
    { label: "Modern", value: "Modern" },
    { label: "Modern Farmhouse", value: "Modern Farmhouse" },
    { label: "Mountain Modern", value: "Mountain Modern" },
    { label: "Rustic", value: "Rustic" },
    { label: "Scandinavian", value: "Scandinavian" },
    { label: "Traditional", value: "Traditional" },
    { label: "Transitional", value: "Transitional" },
  ];

  const stylesOptions = type === 'interior' ? INTERIOR_STYLES : EXTERIOR_STYLES;

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      setImageDataUrl(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleGenerate = async () => {
    if (!imageDataUrl || !selectedStyle) {
      alert('Please upload an image and select a style.');
      return;
    }

    if (user) {
      if (!emailConfirmed) {
        alert('Please confirm your email before using the app.');
        setIsSignInPromptOpen(true);
        return;
      }

      if (generateCount >= AUTH_GENERATE_LIMIT) {
        setIsSubscribePromptOpen(true);
        return;
      }
    } else {
      if (generateCount >= UNAUTH_GENERATE_LIMIT) {
        setIsSignInPromptOpen(true);
        return;
      }
    }

    await updateGenerateCount();

    navigate('/results', {
      state: {
        imageDataUrl,
        style: selectedStyle.value,
        type,
      },
    });
  };

  return (
    <div className="p-4 sm:p-6 w-full bg-gray-800 text-white min-h-screen flex flex-col items-center">
      <h2 className="text-2xl font-bold mb-4 text-center">
        Upload Your {type === 'interior' ? 'Interior' : 'Exterior'} Image
      </h2>

      <div className="w-full sm:w-2/3">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between sm:space-x-4 mb-6">
          <div className="mb-4 sm:mb-0 flex-1">
            <label className="block">
              <span className="sr-only">Choose image</span>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="block w-full text-sm text-gray-500
                  file:mr-4 file:py-2 file:px-4
                  file:rounded file:border-0
                  file:text-sm file:font-semibold
                  file:bg-blue-500 file:text-white
                  hover:file:bg-blue-600"
              />
            </label>
          </div>

          <div className="mb-4 sm:mb-0 flex-1">
            <Select
              placeholder="Select Style"
              options={stylesOptions}
              value={selectedStyle}
              onChange={setSelectedStyle}
              isMulti={false}
              closeMenuOnSelect={true}
              className="text-black"
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: 'white',
                  color: 'black',
                  borderColor: '#CBD5E0',
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: 'black',
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused ? '#F7FAFC' : 'white',
                  color: 'black',
                }),
                menu: (provided) => ({
                  ...provided,
                  backgroundColor: 'white',
                  color: 'black',
                }),
              }}
            />
          </div>

          <div className="flex-1">
            <button
              onClick={handleGenerate}
              className="w-full sm:w-auto bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Generate
            </button>
          </div>
        </div>

        {imageDataUrl && (
          <div className="mt-4">
            <img
              src={imageDataUrl}
              alt="Uploaded"
              className="max-w-full h-auto"
            />
          </div>
        )}
      </div>

      <SignInPrompt
        isOpen={isSignInPromptOpen}
        onClose={() => setIsSignInPromptOpen(false)}
      />

      <SubscribePrompt
        isOpen={isSubscribePromptOpen}
        onClose={() => setIsSubscribePromptOpen(false)}
      />
    </div>
  );
}

export default Upload;