import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ImageGrid from '../components/ImageGrid';
import Select from 'react-select';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import SignInPrompt from '../components/SignInPrompt';
import SubscribePrompt from '../components/SubscribePrompt';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebaseClient';
import { doc, getDoc, setDoc, updateDoc, addDoc, collection } from 'firebase/firestore';

function Results() {
  const location = useLocation();
  const navigate = useNavigate();
  const { imageDataUrl, style, type } = location.state || {};

  const { user, emailConfirmed, userData, incrementGenerationCount } = useAuth();

  const [generationsHistory, setGenerationsHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const hasProcessedSet = useRef(false);
  const controllerRef = useRef(null);
  const [error, setError] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({
    style: null,
    color: null,
  });
  const intentionalAbort = useRef(false);
  const hasGeneratedFirstImages = useRef(false);
  const [isSignInPromptOpen, setIsSignInPromptOpen] = useState(false);
  const [isSubscribePromptOpen, setIsSubscribePromptOpen] = useState(false);

  const UNAUTH_GENERATE_LIMIT = 2;
  const AUTH_GENERATE_LIMIT = 50;

  // Get generation count from Firestore for authenticated users or localStorage for unauthenticated users
  const getGenerateCount = () => {
    if (user) {
      return userData ? userData.generation_count : 0;
    } else {
      return parseInt(localStorage.getItem('generateCount') || '0', 10);
    }
  };

  // Update generation count in Firestore for authenticated users or localStorage for unauthenticated users
  const updateGenerateCount = async () => {
    if (user) {
      const userDocRef = doc(db, 'user_data', user.uid);
      const userDoc = await getDoc(userDocRef);
      const currentCount = userDoc.exists() ? (userDoc.data().generation_count || 0) : 0;
      await updateDoc(userDocRef, { generation_count: currentCount + 1 });
      incrementGenerationCount();
    } else {
      const currentCount = getGenerateCount();
      localStorage.setItem('generateCount', (currentCount + 1).toString());
    }
  };

  // Style and color options for the dropdown
  const EXTERIOR_STYLES = [
    { label: "Coastal", value: "Coastal" },
    { label: "Coastal Modern", value: "Coastal Modern" },
    { label: "Contemporary", value: "Contemporary" },
    { label: "Craftsman", value: "Craftsman" },
    { label: "Mid Century Modern", value: "Mid Century Modern" },
    { label: "Modern Farmhouse", value: "Modern Farmhouse" },
    { label: "Mountain Modern", value: "Mountain Modern" },
    { label: "Scandinavian", value: "Scandinavian" },
    { label: "Southwestern", value: "Southwestern" },
  ];

  const EXTERIOR_COLORS = [
    { label: "Black", value: "Black" },
    { label: "White", value: "White" },
    { label: "Grey", value: "Grey" },
    { label: "Blue", value: "Blue" },
    { label: "Beige", value: "Beige" },
    { label: "Red", value: "Red" },
  ];

  const INTERIOR_STYLES = [
    { label: "Coastal", value: "Coastal" },
    { label: "Contemporary", value: "Contemporary" },
    { label: "Glam", value: "Glam" },
    { label: "Industrial", value: "Industrial" },
    { label: "Mediterranean", value: "Mediterranean" },
    { label: "Mid-Century Modern", value: "Mid-Century Modern" },
    { label: "Minimalist", value: "Minimalist" },
    { label: "Modern", value: "Modern" },
    { label: "Modern Farmhouse", value: "Modern Farmhouse" },
    { label: "Mountain Modern", value: "Mountain Modern" },
    { label: "Rustic", value: "Rustic" },
    { label: "Scandinavian", value: "Scandinavian" },
    { label: "Traditional", value: "Traditional" },
    { label: "Transitional", value: "Transitional" },
  ];

  const INTERIOR_COLORS = [
    { label: "White", value: "White" },
    { label: "Gray", value: "Gray" },
    { label: "Beige", value: "Beige" },
    { label: "Blue", value: "Blue" },
    { label: "Green", value: "Green" },
    { label: "Yellow", value: "Yellow" },
    { label: "Orange", value: "Orange" },
    { label: "Red", value: "Red" },
    { label: "Purple", value: "Purple" },
    { label: "Brown", value: "Brown" },
  ];

  const OPTIONS = type === 'interior' 
    ? [
        { label: 'Style', options: INTERIOR_STYLES },
        { label: 'Color', options: INTERIOR_COLORS },
      ]
    : [
        { label: 'Style', options: EXTERIOR_STYLES },
        { label: 'Color', options: EXTERIOR_COLORS },
      ];

  // Initialize generation and check limits
  useEffect(() => {
    const initializeGeneration = async () => {
      const generateCount = getGenerateCount();

      if (user) {
        if (!emailConfirmed) {
          alert('Please confirm your email before using the app.');
          setIsSignInPromptOpen(true);
          return;
        }

        if (generateCount >= AUTH_GENERATE_LIMIT) {
          setIsSubscribePromptOpen(true);
          return;
        }
      } else {
        if (generateCount >= UNAUTH_GENERATE_LIMIT) {
          setIsSignInPromptOpen(true);
          return;
        }
      }

      if (!hasGeneratedFirstImages.current) {
        setGenerationsHistory([]);
        await generateImages();
        hasGeneratedFirstImages.current = true;
      }
    };

    initializeGeneration();
  }, [user, emailConfirmed]);

  // Generate images function
  const generateImages = async (selectedStyle = style, selectedColor = null) => {
    const generateCount = getGenerateCount();

    if (user && generateCount >= AUTH_GENERATE_LIMIT) {
      setIsSubscribePromptOpen(true);
      return;
    } else if (!user && generateCount >= UNAUTH_GENERATE_LIMIT) {
      setIsSignInPromptOpen(true);
      return;
    }

    setLoading(true);
    setError(null);
    intentionalAbort.current = false;

    const formData = new FormData();
    const blob = dataURLToBlob(imageDataUrl);
    formData.append('image', blob, 'uploaded_image.jpg');
    formData.append('housetype', selectedStyle);
    if (selectedColor) {
      formData.append('color', selectedColor);
    }
    formData.append('inout', type);

    const controller = new AbortController();
    const signal = controller.signal;
    controllerRef.current = controller;

    const receivedURLs = {
      original: null,
      gen1: null,
      gen2: null,
      gen3: null,
    };

    const newGeneration = {
      original: imageDataUrl,
      generated: [null, null, null],
      loading: [true, true, true],
      style: selectedStyle || style,
      color: selectedColor || null,
    };

    setGenerationsHistory(prev => [newGeneration, ...prev]);
    setCurrentPage(0);
    hasProcessedSet.current = false;

    const timeoutId = setTimeout(() => {
      controller.abort();
      setError('Sorry, there was an error processing your image. Please try again.');
      setLoading(false);
    }, 60000);

    try {
      const response = await fetch('https://exteriormodifai.com/api/upload', {
        method: 'POST',
        body: formData,
        signal: signal,
      });

      clearTimeout(timeoutId);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let buffer = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        buffer += decoder.decode(value, { stream: true });

        let startIndex = 0;
        while (true) {
          const endIndex = buffer.indexOf('\n', startIndex);
          if (endIndex === -1) break;

          const line = buffer.slice(startIndex, endIndex).trim();
          startIndex = endIndex + 1;

          if (line.startsWith('data:')) {
            try {
              const jsonData = JSON.parse(line.slice(5));
              console.log('JSON data received:', jsonData);

              if (jsonData.error) {
                console.error('API Error:', jsonData.error);
                setError(`Sorry, there was an error processing your image: ${jsonData.error}. Please try again.`);
                continue;
              }

              if (hasProcessedSet.current) continue;

              if (jsonData.original_image_url && !receivedURLs.original) {
                receivedURLs.original = jsonData.original_image_url;
                console.log('Original Image URL:', jsonData.original_image_url);
              }

              if (jsonData.generated_image_url_1 && !receivedURLs.gen1) {
                receivedURLs.gen1 = jsonData.generated_image_url_1;
                console.log('Generated Image URL 1:', jsonData.generated_image_url_1);
                updateGenerationImage(0, jsonData.generated_image_url_1);
              }

              if (jsonData.generated_image_url_2 && !receivedURLs.gen2) {
                receivedURLs.gen2 = jsonData.generated_image_url_2;
                console.log('Generated Image URL 2:', jsonData.generated_image_url_2);
                updateGenerationImage(1, jsonData.generated_image_url_2);
              }

              if (jsonData.generated_image_url_3 && !receivedURLs.gen3) {
                receivedURLs.gen3 = jsonData.generated_image_url_3;
                console.log('Generated Image URL 3:', jsonData.generated_image_url_3);
                updateGenerationImage(2, jsonData.generated_image_url_3);
              }

              if (receivedURLs.gen1 && receivedURLs.gen2 && receivedURLs.gen3) {
                hasProcessedSet.current = true;
                intentionalAbort.current = true;
                controller.abort();
                break;
              }

            } catch (e) {
              console.error('Failed to parse JSON:', line);
            }
          }
        }
        buffer = buffer.slice(startIndex);
      }

      await updateGenerateCount();
    } catch (error) {
      if (error.name === 'AbortError') {
        if (intentionalAbort.current) {
          console.log('Fetch aborted intentionally after processing completed.');
        } else {
          console.error('Fetch aborted unexpectedly:', error);
        }
      } else if (error.message.includes('network error')) {
        console.error('Network Error:', error);
        setError('A network error occurred. Please check your internet connection and try again.');
      } else {
        console.error('Error:', error);
        setError('Sorry, there was an error processing your image. Please try again.');
      }
    } finally {
      clearTimeout(timeoutId);
      setLoading(false);
    }
  };

  // Update generation image in the history
  const updateGenerationImage = (index, url) => {
    setGenerationsHistory(prevHistory => {
      const updatedHistory = [...prevHistory];
      if (updatedHistory.length > 0) {
        const latestGeneration = { ...updatedHistory[0] };
        latestGeneration.generated[index] = url;
        latestGeneration.loading[index] = false;
        let label = '';
        if (latestGeneration.style) {
          label += `${latestGeneration.style}`;
        }
        if (latestGeneration.color) {
          label += ` ${latestGeneration.color}`;
        }
        label += ` ${index + 1}`;
        latestGeneration.generatedLabels = latestGeneration.generatedLabels || [];
        latestGeneration.generatedLabels[index] = label;
        updatedHistory[0] = latestGeneration;
      }
      return updatedHistory;
    });
  };

  const handleChooseStyle = () => {
    navigate('/upload', { state: { type } });
  };

  const handleGenerateMore = async () => {
    if (!selectedOptions.style && !selectedOptions.color) {
      alert('Please select either a style or a color to generate images.');
      return;
    }
    if (selectedOptions.style && selectedOptions.color) {
      alert('Please select either a style or a color, not both.');
      return;
    }

    await generateImages(
      selectedOptions.style ? selectedOptions.style.value : style,
      selectedOptions.color ? selectedOptions.color.value : null
    );
  };

  const handlePrevPage = () => {
    if (currentPage < generationsHistory.length - 1) {
      setCurrentPage(prev => prev + 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage > 0) {
      setCurrentPage(prev => prev - 1);
    }
  };

  const dataURLToBlob = (dataurl) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
  };

  const pinDesign = async (imageUrl) => {
    if (!user) {
      setIsSignInPromptOpen(true);
      return;
    }
    const designRef = collection(db, `users/${user.uid}/designs`);
    await addDoc(designRef, {
      imageUrl,
      type,
      createdAt: new Date(),
      sharedToFeed: false
    });
    alert('Design pinned successfully!');
  };

  const shareToFeed = async (imageUrl) => {
    if (!user) {
      setIsSignInPromptOpen(true);
      return;
    }
    await addDoc(collection(db, 'communityFeed'), {
      imageUrl,
      type,
      userId: user.uid,
      createdAt: new Date(),
      likes: 0,
      comments: []
    });
    alert('Design shared to community feed!');
  };

  return (
    <div className="p-4 sm:p-6 w-full bg-gray-800 text-white min-h-screen">
      <h2 className="text-2xl font-bold mb-4 text-center sm:text-left text-gray-200">Generated Images</h2>

      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between sm:space-x-4 mb-6 w-full sm:w-2/3 mx-auto">
        <button
          className="btn bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 w-full sm:w-auto mb-4 sm:mb-0"
          onClick={handleChooseStyle}
        >
          Back
        </button>

        <div className="flex-1 sm:mx-4">
          <Select
            placeholder="Select Style or Color"
            options={OPTIONS}
            value={selectedOptions.style || selectedOptions.color || null}
            onChange={(selected) => {
              setSelectedOptions({
                style: OPTIONS[0].options.find(opt => opt.value === selected.value) || null,
                color: OPTIONS[1].options.find(opt => opt.value === selected.value) || null,
              });
            }}
            isMulti={false}
            closeMenuOnSelect={true}
            className="text-black"
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: 'white',
                color: 'black',
                borderColor: '#CBD5E0',
              }),
              singleValue: (provided) => ({
                ...provided,
                color: 'black',
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused ? '#F7FAFC' : 'white',
                color: 'black',
              }),
              menu: (provided) => ({
                ...provided,
                backgroundColor: 'white',
                color: 'black',
              }),
            }}
          />
        </div>

        <button
          className="btn bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 w-full sm:w-auto mt-4 sm:mt-0"
          onClick={handleGenerateMore}
          disabled={loading}
        >
          {loading ? 'Generating...' : 'Generate'}
        </button>
      </div>

      {error && (
        <div className="w-full sm:w-2/3 mx-auto mb-4">
          <div className="bg-yellow-500 text-black p-4 rounded">
            {error}
          </div>
        </div>
      )}

      {generationsHistory.length > 0 && (
        <ImageGrid
          originalImage={generationsHistory[currentPage].original}
          generatedImages={generationsHistory[currentPage].generated}
          generatedLabels={generationsHistory[currentPage].generatedLabels}
          loading={generationsHistory[currentPage].loading}
          onPin={pinDesign}
          onShare={shareToFeed}
        />
      )}

      {generationsHistory.length > 1 && (
        <div className="flex justify-end mt-6 space-x-2 w-full sm:w-2/3 mx-auto">
          <button
            className="btn bg-gray-300 p-2 rounded hover:bg-gray-400"
            onClick={handlePrevPage}
            disabled={currentPage >= generationsHistory.length - 1}
            aria-label="Previous Page"
          >
            <FaArrowLeft />
          </button>
          <span className="self-center text-gray-200">
            {currentPage + 1} / {generationsHistory.length}
          </span>
          <button
            className="btn bg-gray-300 p-2 rounded hover:bg-gray-400"
            onClick={handleNextPage}
            disabled={currentPage === 0}
            aria-label="Next Page"
          >
            <FaArrowRight />
          </button>
        </div>
      )}

      <SignInPrompt
        isOpen={isSignInPromptOpen}
        onClose={() => setIsSignInPromptOpen(false)}
      />

      <SubscribePrompt
        isOpen={isSubscribePromptOpen}
        onClose={() => setIsSubscribePromptOpen(false)}
      />
    </div>
  );
}

export default Results;