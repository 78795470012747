// client/src/components/DoorReplacementGrid.js

import React, { useRef, useEffect, useState, useCallback } from 'react';
import { FaEdit, FaCheck, FaPlus, FaMinus } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import axios from 'axios';

// Custom hook to detect if the device is mobile
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

function DoorReplacementGrid({
  houseImage,
  detectedDoors,
  pageType,
  isFirstUpload,
  setIsFirstUpload,
  setDetectedDoors
}) {
  const canvasRef = useRef(null);
  const catalogRef = useRef(null);
  const [editMode, setEditMode] = useState(false);
  const [activeDoorIndex, setActiveDoorIndex] = useState(null);
  const [dragPoint, setDragPoint] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);
  const [houseImageObj, setHouseImageObj] = useState(null);
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [catalogData, setCatalogData] = useState({});
  const [filteredCatalog, setFilteredCatalog] = useState([]);
  const [selectedDoorImages, setSelectedDoorImages] = useState({});
  const [filters, setFilters] = useState({
    price: 'All',
    color: 'All',
    style: 'All',
  });
  const [doorImageCache, setDoorImageCache] = useState({});
  const [zoomLevel, setZoomLevel] = useState(1);

  const isMobile = useIsMobile();

  // Define touch detection radius and corner point sizes based on device type
  const TOUCH_DETECTION_RADIUS = isMobile ? 100 : 20; // Increased by 5x for mobile
  const CORNER_POINT_RADIUS = isMobile ? 120 : 13; // 120px for mobile, 13px for desktop

  const toggleEditMode = () => {
    setEditMode(!editMode);
    setActiveDoorIndex(null);
    setDragPoint(null);
  };

  // Fetch catalog data
  useEffect(() => {
    const fetchCatalogData = async () => {
      try {
        const response = await axios.get('https://prodappmodifai.link/GetCatalog/all_home_objects');
        setCatalogData(response.data);
      } catch (error) {
        console.error('Error fetching catalog data:', error);
        alert('Failed to fetch catalog data. Please try again later.');
      }
    };

    fetchCatalogData();
  }, []);

  // Determine the types of detected doors
  const detectedDoorTypes = useCallback(() => {
    const types = new Set();
    detectedDoors.forEach(door => {
      if (door.label.includes("garage:one-car")) {
        types.add("garage-one-car");
      } else if (door.label.includes("garage:two-car")) {
        types.add("garage-two-car");
      } else if (door.label.includes("front door:single")) {
        types.add("frontdoor-single");
      } else if (door.label.includes("front door:double")) {
        types.add("frontdoor-double");
      }
    });
    return types;
  }, [detectedDoors]);

  // Filter catalog data based on pageType, detected door types, and filters
  useEffect(() => {
    const filterCatalog = () => {
      const detectedTypes = detectedDoorTypes();

      let relevantCatalogItems = [];

      if (pageType === 'garage') {
        // Collect catalog items for garage doors
        if (detectedTypes.has("garage-one-car") && !detectedTypes.has("garage-two-car")) {
          relevantCatalogItems = catalogData['one_car_garage'] || [];
        } else if (!detectedTypes.has("garage-one-car") && detectedTypes.has("garage-two-car")) {
          relevantCatalogItems = catalogData['two_car_garage'] || [];
        } else if (detectedTypes.has("garage-one-car") && detectedTypes.has("garage-two-car")) {
          relevantCatalogItems = catalogData['two_car_garage'] || [];
        } else {
          relevantCatalogItems = [
            ...(catalogData['one_car_garage'] || []),
            ...(catalogData['two_car_garage'] || []),
          ];
        }
      } else if (pageType === 'frontdoor') {
        // Collect catalog items for front doors based on detected types
        if (detectedTypes.has("frontdoor-single") && !detectedTypes.has("frontdoor-double")) {
          relevantCatalogItems = (catalogData['front_door'] || []).filter(item => item.typeID === 3);
        } else if (!detectedTypes.has("frontdoor-single") && detectedTypes.has("frontdoor-double")) {
          relevantCatalogItems = (catalogData['front_door'] || []).filter(item => item.typeID === 4);
        } else if (detectedTypes.has("frontdoor-single") && detectedTypes.has("frontdoor-double")) {
          relevantCatalogItems = catalogData['front_door'] || [];
        } else {
          relevantCatalogItems = catalogData['front_door'] || [];
        }
      }

      let filtered = relevantCatalogItems.filter(item => {
        const priceMatch = filters.price === 'All' || item.price === filters.price;
        const colorMatch = filters.color === 'All' || item.color === filters.color;
        const styleMatch = filters.style === 'All' || item.style === filters.style;

        return priceMatch && colorMatch && styleMatch;
      });

      // Group filtered items by 'name' and keep one item per name
      const catalogItemsByName = {};
      filtered.forEach(item => {
        if (!catalogItemsByName[item.name]) {
          catalogItemsByName[item.name] = item;
        } else {
          // Prefer specific types if both exist
          if (pageType === 'garage' && item.typeID === 2) {
            catalogItemsByName[item.name] = item;
          }
          if (pageType === 'frontdoor' && item.typeID === 4) {
            catalogItemsByName[item.name] = item;
          }
        }
      });

      filtered = Object.values(catalogItemsByName);

      setFilteredCatalog(filtered);
    };

    filterCatalog();
  }, [catalogData, pageType, filters, detectedDoorTypes]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleCatalogImageClick = (item) => {
    // Find all catalog items with the same name from relevant catalogs
    let itemsWithSameName = [];

    if (pageType === 'garage') {
      const oneCarItems = catalogData['one_car_garage'] || [];
      const twoCarItems = catalogData['two_car_garage'] || [];
      itemsWithSameName = [
        ...oneCarItems.filter(ci => ci.name === item.name),
        ...twoCarItems.filter(ci => ci.name === item.name),
      ];
    } else if (pageType === 'frontdoor') {
      const frontDoorItems = catalogData['front_door'] || [];
      itemsWithSameName = frontDoorItems.filter(ci => ci.name === item.name);
    }

    // Find the images for different types
    const oneCarImage = itemsWithSameName.find(ci => ci.typeID === 1);
    const twoCarImage = itemsWithSameName.find(ci => ci.typeID === 2);
    const singleFrontDoorImage = itemsWithSameName.find(ci => ci.typeID === 3);
    const doubleFrontDoorImage = itemsWithSameName.find(ci => ci.typeID === 4);

    setSelectedDoorImages(prevImages => {
      const newImages = { ...prevImages };
      detectedDoors.forEach((door, index) => {
        if (pageType === 'garage') {
          if (door.label.includes("garage:one-car")) {
            if (oneCarImage) {
              newImages[index] = oneCarImage.url_original;
            } else if (twoCarImage) {
              newImages[index] = twoCarImage.url_original;
            } else {
              newImages[index] = item.url_original;
            }
          } else if (door.label.includes("garage:two-car")) {
            if (twoCarImage) {
              newImages[index] = twoCarImage.url_original;
            } else if (oneCarImage) {
              newImages[index] = oneCarImage.url_original;
            } else {
              newImages[index] = item.url_original;
            }
          }
        } else if (pageType === 'frontdoor') {
          if (door.label.includes("front door:single")) {
            if (singleFrontDoorImage) {
              newImages[index] = singleFrontDoorImage.url_original;
            } else if (doubleFrontDoorImage) {
              newImages[index] = doubleFrontDoorImage.url_original;
            } else {
              newImages[index] = item.url_original;
            }
          } else if (door.label.includes("front door:double")) {
            if (doubleFrontDoorImage) {
              newImages[index] = doubleFrontDoorImage.url_original;
            } else if (singleFrontDoorImage) {
              newImages[index] = singleFrontDoorImage.url_original;
            } else {
              newImages[index] = item.url_original;
            }
          }
        }
      });
      return newImages;
    });
  };

  const handleCanvasMouseDown = (e) => {
    if (!editMode) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = (e.clientX - rect.left) * (canvas.width / rect.width);
    const y = (e.clientY - rect.top) * (canvas.height / rect.height);

    let closestCorner = null;
    let minDistance = Infinity;
    let closestDoorIndex = null;

    detectedDoors.forEach((door, doorIndex) => {
      const corners = ['topLeft', 'topRight', 'bottomRight', 'bottomLeft'];
      corners.forEach(corner => {
        const distance = Math.hypot(x - door[corner].x, y - door[corner].y);
        if (distance < minDistance && distance < TOUCH_DETECTION_RADIUS) {
          minDistance = distance;
          closestCorner = corner;
          closestDoorIndex = doorIndex;
        }
      });
    });

    if (closestCorner !== null) {
      setActiveDoorIndex(closestDoorIndex);
      setDragPoint(closestCorner);
    }
  };

  const handleCanvasMouseMove = (e) => {
    if (!editMode || activeDoorIndex === null || dragPoint === null) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = Math.max(0, Math.min((e.clientX - rect.left) * (canvas.width / rect.width), canvas.width));
    const y = Math.max(0, Math.min((e.clientY - rect.top) * (canvas.height / rect.height), canvas.height));

    setDetectedDoors(prevDoors => {
      const newDoors = [...prevDoors];
      const updatedDoor = { ...newDoors[activeDoorIndex] };

      // Update the dragged corner
      updatedDoor[dragPoint] = { x, y };

      // Adjust opposite corners to maintain rectangle shape
      if (dragPoint === 'topLeft') {
        updatedDoor.topRight.y = y;
        updatedDoor.bottomLeft.x = x;
      } else if (dragPoint === 'topRight') {
        updatedDoor.topLeft.y = y;
        updatedDoor.bottomRight.x = x;
      } else if (dragPoint === 'bottomRight') {
        updatedDoor.bottomLeft.y = y;
        updatedDoor.topRight.x = x;
      } else if (dragPoint === 'bottomLeft') {
        updatedDoor.bottomRight.y = y;
        updatedDoor.topLeft.x = x;
      }

      newDoors[activeDoorIndex] = updatedDoor;
      return newDoors;
    });
  };

  const handleCanvasMouseUp = () => {
    setDragPoint(null);
    setActiveDoorIndex(null);
  };

  // Event handlers for touch interactions (mobile devices)
  const handleCanvasTouchStart = (e) => {
    if (!editMode) return;

    e.preventDefault();
    const touch = e.touches[0];
    handleCanvasMouseDown({
      clientX: touch.clientX,
      clientY: touch.clientY,
    });
  };

  const handleCanvasTouchMove = (e) => {
    if (!editMode || activeDoorIndex === null || dragPoint === null) return;

    e.preventDefault();
    const touch = e.touches[0];
    handleCanvasMouseMove({
      clientX: touch.clientX,
      clientY: touch.clientY,
    });
  };

  const handleCanvasTouchEnd = (e) => {
    e.preventDefault();
    handleCanvasMouseUp();
  };

  const drawDoorImage = useCallback((ctx, img, door) => {
    const { topLeft, topRight, bottomRight, bottomLeft } = door;

    ctx.save();

    ctx.beginPath();
    ctx.moveTo(topLeft.x, topLeft.y);
    ctx.lineTo(topRight.x, topRight.y);
    ctx.lineTo(bottomRight.x, bottomRight.y);
    ctx.lineTo(bottomLeft.x, bottomLeft.y);
    ctx.closePath();
    ctx.clip();

    const width = Math.hypot(topRight.x - topLeft.x, topRight.y - topLeft.y);
    const height = Math.hypot(bottomLeft.x - topLeft.x, bottomLeft.y - topLeft.y);
    const angle = Math.atan2(topRight.y - topLeft.y, topRight.x - topLeft.x);

    ctx.save();

    ctx.translate(topLeft.x, topLeft.y);
    ctx.rotate(angle);

    ctx.drawImage(img, 0, 0, width * zoomLevel, height * zoomLevel);

    ctx.restore();

    ctx.restore();
  }, [zoomLevel]);

  const drawDoors = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (houseImageObj) {
      ctx.drawImage(houseImageObj, 0, 0, canvas.width, canvas.height);
    }

    detectedDoors.forEach((door, index) => {
      const doorImgSrc = selectedDoorImages[index] || getDoorImageSrc(door, pageType);
      const doorImg = doorImageCache[doorImgSrc];
      if (doorImg) {
        drawDoorImage(ctx, doorImg, door);
      } else if (doorImgSrc) {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = () => {
          setDoorImageCache(prevCache => ({
            ...prevCache,
            [doorImgSrc]: img,
          }));
        };
        img.src = doorImgSrc;
      }

      if (editMode) {
        drawDoorOverlay(ctx, door, index === activeDoorIndex);
      }
    });
  }, [
    detectedDoors,
    doorImageCache,
    selectedDoorImages,
    editMode,
    activeDoorIndex,
    houseImageObj,
    pageType,
    drawDoorImage,
    zoomLevel
  ]);

  const drawDoorOverlay = useCallback((ctx, door, isActive) => {
    const { topLeft, topRight, bottomRight, bottomLeft } = door;

    ctx.beginPath();
    ctx.moveTo(topLeft.x, topLeft.y);
    ctx.lineTo(topRight.x, topRight.y);
    ctx.lineTo(bottomRight.x, bottomRight.y);
    ctx.lineTo(bottomLeft.x, bottomLeft.y);
    ctx.closePath();

    ctx.strokeStyle = isActive ? 'rgba(255, 0, 0, 0.9)' : 'rgba(0, 0, 255, 0.9)';
    ctx.lineWidth = isMobile ? 30 : 4; // Adjust line width if needed
    ctx.stroke();

    // Draw corner points
    [topLeft, topRight, bottomRight, bottomLeft].forEach(point => {
      ctx.beginPath();
      ctx.arc(point.x, point.y, CORNER_POINT_RADIUS, 0, 2 * Math.PI); // Adjusted radius
      ctx.fillStyle = isActive ? 'rgba(255, 0, 0, 0.9)' : 'rgba(0, 0, 255, 0.9)';
      ctx.fill();
      ctx.strokeStyle = 'white';
      ctx.lineWidth = 2;
      ctx.stroke();
    });
  }, [isMobile, CORNER_POINT_RADIUS]);

  useEffect(() => {
    if (houseImage && detectedDoors.length > 0) {
      const canvas = canvasRef.current;
      const image = new Image();
      image.crossOrigin = "anonymous";
      image.src = houseImage;
      image.onload = () => {
        const maxWidth = canvas.parentElement.clientWidth;
        const scale = maxWidth / image.width;
        const canvasWidth = image.width * scale * zoomLevel;
        const canvasHeight = image.height * scale * zoomLevel;
        canvas.width = image.width;
        canvas.height = image.height;
        canvas.style.width = `${canvasWidth}px`;
        canvas.style.height = `${canvasHeight}px`;
        setHouseImageObj(image);
        setCanvasHeight(canvasHeight);

        if (catalogRef.current) {
          catalogRef.current.style.maxHeight = `${canvasHeight}px`;
        }
      };
    }
  }, [houseImage, detectedDoors, zoomLevel]);

  useEffect(() => {
    drawDoors();
  }, [drawDoors]);

  useEffect(() => {
    if (isFirstUpload && detectedDoors.length > 0) {
      setShowPrompt(true);
      setTimeout(() => {
        setShowPrompt(false);
        setIsFirstUpload(false);
      }, 3000);
    }
  }, [isFirstUpload, detectedDoors.length, setIsFirstUpload]);

  const getDoorImageSrc = (door, pageType) => {
    if (pageType === 'garage') {
      return door.label.includes("garage:one-car") ? "/garage1.PNG" : "/garage2.PNG";
    } else if (pageType === 'frontdoor') {
      if (door.label.includes("front door:single")) {
        return "/frontdoor-single.PNG";
      } else if (door.label.includes("front door:double")) {
        return "/frontdoor-double.PNG";
      } else {
        return "/frontdoor-placeholder.PNG";
      }
    }
    return '';
  };

  const handleCompleteEdit = () => {
    setEditMode(false);
    setActiveDoorIndex(null);
    setDragPoint(null);
  };

  // Get unique values for filters
  const getUniqueValues = (key) => {
    const detectedTypes = detectedDoorTypes();

    let relevantCatalogItems = [];

    if (pageType === 'garage') {
      if (detectedTypes.has("garage-one-car") && !detectedTypes.has("garage-two-car")) {
        relevantCatalogItems = catalogData['one_car_garage'] || [];
      } else if (!detectedTypes.has("garage-one-car") && detectedTypes.has("garage-two-car")) {
        relevantCatalogItems = catalogData['two_car_garage'] || [];
      } else if (detectedTypes.has("garage-one-car") && detectedTypes.has("garage-two-car")) {
        relevantCatalogItems = catalogData['two_car_garage'] || [];
      } else {
        relevantCatalogItems = [
          ...(catalogData['one_car_garage'] || []),
          ...(catalogData['two_car_garage'] || []),
        ];
      }
    } else if (pageType === 'frontdoor') {
      if (detectedTypes.has("frontdoor-single") && !detectedTypes.has("frontdoor-double")) {
        relevantCatalogItems = (catalogData['front_door'] || []).filter(item => item.typeID === 3);
      } else if (!detectedTypes.has("frontdoor-single") && detectedTypes.has("frontdoor-double")) {
        relevantCatalogItems = (catalogData['front_door'] || []).filter(item => item.typeID === 4);
      } else if (detectedTypes.has("frontdoor-single") && detectedTypes.has("frontdoor-double")) {
        relevantCatalogItems = catalogData['front_door'] || [];
      } else {
        relevantCatalogItems = catalogData['front_door'] || [];
      }
    }

    const values = relevantCatalogItems.map(item => item[key]).filter(value => value);
    return ['All', ...Array.from(new Set(values))];
  };

  const handleZoomIn = () => {
    setZoomLevel(prev => Math.min(prev + 0.2, 3));
  };

  const handleZoomOut = () => {
    setZoomLevel(prev => Math.max(prev - 0.2, 1));
  };

  return (
    <div className="flex flex-col items-center mt-4 relative overflow-auto w-full max-w-screen-xl mx-auto">
      <div className="flex flex-col lg:flex-row w-full">
        {/* Canvas */}
        <div className="relative flex-1 flex flex-col items-center">
          <div className="relative w-full">
            <canvas
              ref={canvasRef}
              className="border rounded w-full h-auto touch-none"
              onMouseDown={handleCanvasMouseDown}
              onMouseMove={handleCanvasMouseMove}
              onMouseUp={handleCanvasMouseUp}
              onMouseLeave={handleCanvasMouseUp}
              onTouchStart={handleCanvasTouchStart}
              onTouchMove={handleCanvasTouchMove}
              onTouchEnd={handleCanvasTouchEnd}
            />

            {/* Edit Icon */}
            <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
              {editMode ? (
                <FaCheck
                  className="absolute top-2 right-2 cursor-pointer bg-green-600 rounded-full p-2 opacity-90 hover:opacity-100 transition-opacity"
                  onClick={handleCompleteEdit}
                  aria-label="Complete Editing"
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => { if (e.key === 'Enter') handleCompleteEdit(); }}
                />
              ) : (
                <FaEdit
                  className="absolute top-2 right-2 cursor-pointer bg-blue-600 rounded-full p-2 opacity-90 hover:opacity-100 transition-opacity"
                  onClick={toggleEditMode}
                  aria-label="Edit Doors"
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => { if (e.key === 'Enter') toggleEditMode(); }}
                />
              )}
            </IconContext.Provider>

            {/* Zoom Controls */}
            <div className="absolute bottom-2 left-2 flex space-x-2">
              <button
                onClick={handleZoomIn}
                className="bg-gray-700 text-white p-2 rounded-full hover:bg-gray-600 transition-colors"
                aria-label="Zoom In"
              >
                <FaPlus />
              </button>
              <button
                onClick={handleZoomOut}
                className="bg-gray-700 text-white p-2 rounded-full hover:bg-gray-600 transition-colors"
                aria-label="Zoom Out"
              >
                <FaMinus />
              </button>
            </div>

            {showPrompt && (
              <div className="absolute top-12 right-12 bg-white text-black text-sm p-2 rounded shadow-lg max-w-xs">
                Click edit to adjust door size or position
              </div>
            )}
          </div>
        </div>

        {/* Catalog List */}
        <div
          ref={catalogRef}
          className="w-full lg:w-1/4 overflow-y-auto bg-gray-800 p-4 mt-4 lg:mt-0 lg:ml-4 rounded"
          style={{ maxHeight: `${canvasHeight}px` }}
        >
          <h2 className="text-xl mb-4">Catalog</h2>
          {/* Filters */}
          <div className="mb-6">
            <label className="block mb-1">Price:</label>
            <select
              name="price"
              value={filters.price}
              onChange={handleFilterChange}
              className="w-full p-2 mb-4 text-black rounded bg-gray-700"
            >
              {getUniqueValues('price').map(price => (
                <option key={price} value={price}>{price}</option>
              ))}
            </select>

            <label className="block mb-1">Color:</label>
            <select
              name="color"
              value={filters.color}
              onChange={handleFilterChange}
              className="w-full p-2 mb-4 text-black rounded bg-gray-700"
            >
              {getUniqueValues('color').map(color => (
                <option key={color} value={color}>{color}</option>
              ))}
            </select>

            <label className="block mb-1">Style:</label>
            <select
              name="style"
              value={filters.style}
              onChange={handleFilterChange}
              className="w-full p-2 mb-4 text-black rounded bg-gray-700"
            >
              {getUniqueValues('style').map(style => (
                <option key={style} value={style}>{style}</option>
              ))}
            </select>
          </div>
          {/* Catalog Images */}
          <div className="grid grid-cols-2 gap-4">
            {filteredCatalog.map(item => (
              <div
                key={item.catalogID}
                className="cursor-pointer hover:opacity-75 w-full h-40 flex items-center justify-center bg-gray-700 rounded"
                onClick={() => handleCatalogImageClick(item)}
              >
                <img
                  src={item.url_original}
                  alt={item.name}
                  className="max-h-full max-w-full object-contain rounded"
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {editMode && (
        <div className="mt-4">
          <p className="text-sm text-gray-300">
            Click and drag the corner points to adjust the door overlay. Click the checkmark when done.
          </p>
        </div>
      )}
    </div>
  );
}

export default DoorReplacementGrid;

