// src/pages/About.js

import React from 'react';

function About() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-6 bg-gray-100">
      <h1 className="text-4xl font-bold mb-4">About Modifai</h1>
      <p className="text-lg text-center max-w-2xl">
        Modifai is dedicated to helping you visualize and transform your living spaces. Whether you're looking to redesign your interior or exterior, our advanced tools and expert guidance are here to bring your dream home to life.
      </p>
    </div>
  );
}

export default About;
