// src/pages/Home.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
// Removed unused import of 'logo'

function Home() {
  const navigate = useNavigate();

  const handleNavigate = (type) => {
    navigate('/upload', { state: { type } });
  };

  const handleDoorReplacement = () => {
    navigate('/door-replacement');
  };

  return (
    <div 
      className="min-h-screen bg-cover bg-center flex flex-col items-center justify-center relative px-4"
      style={{ backgroundImage: `url('/out-3-36.png')` }} // Updated filename
    >
      {/* Header is handled by Header component */}

      {/* Main Content */}
      <main className="text-center text-white flex flex-col items-center space-y-6">
        {/* Visualize Your Dream Home */}
        <h2 className="text-6xl font-extrabold animate-fadeIn">
          Visualize Your Dream Home
        </h2>

        {/* Modifai Your Place */}
        <h1 className="text-3xl font-bold animate-fadeIn">
          Modifai Your Place
        </h1>

        {/* Buttons */}
        <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
          <button 
            className="btn bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg text-lg"
            onClick={() => handleNavigate('interior')}
          >
            Update Interior
          </button>
          <button 
            className="btn bg-green-600 hover:bg-green-700 text-white px-6 py-3 rounded-lg text-lg"
            onClick={() => handleNavigate('exterior')}
          >
            Update Exterior
          </button>
          <button 
            className="btn bg-purple-600 hover:bg-purple-700 text-white px-6 py-3 rounded-lg text-lg"
            onClick={handleDoorReplacement}
          >
            Replace Doors
          </button>
        </div>
      </main>
    </div>
  );
}

export default Home;
