import React, { useState, useEffect } from 'react';
import { db } from '../firebaseClient';
import { collection, query, orderBy, limit, getDocs, where } from 'firebase/firestore';
import DesignCard from '../components/DesignCard';

const CommunityFeed = () => {
  const [designs, setDesigns] = useState([]);
  const [activeTab, setActiveTab] = useState('exterior');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDesigns = async () => {
      setLoading(true);
      setError(null);
      try {
        const designsRef = collection(db, 'communityFeed');
        const q = query(
          designsRef,
          where('type', '==', activeTab),
          orderBy('createdAt', 'desc'),
          limit(48)
        );
        const designsSnapshot = await getDocs(q);
        const designsList = designsSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            createdAt: data.createdAt ? data.createdAt.toDate() : new Date()
          };
        });
        setDesigns(designsList);
      } catch (err) {
        console.error("Error fetching designs:", err);
        if (err.code === 'failed-precondition' || err.code === 'resource-exhausted') {
          setError("We're setting up some things. Please try again in a few minutes.");
        } else {
          setError("Failed to load designs. Please try again later.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchDesigns();
  }, [activeTab]);

  const handleDeleteDesign = (designId) => {
    setDesigns(designs.filter(design => design.id !== designId));
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-white">Community Designs</h1>
      <div className="flex justify-center mb-6">
        <button
          className={`px-4 py-2 mr-2 rounded ${activeTab === 'exterior' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveTab('exterior')}
        >
          Exterior
        </button>
        <button
          className={`px-4 py-2 rounded ${activeTab === 'interior' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveTab('interior')}
        >
          Interior
        </button>
      </div>
      {loading && <p className="text-center">Loading designs...</p>}
      {error && <p className="text-center text-red-500">{error}</p>}
      {!loading && !error && designs.length === 0 && (
        <p className="text-center">No designs found for this category.</p>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {designs.map(design => (
          <DesignCard 
            key={design.id} 
            design={design} 
            isFeed={true}
            onDelete={handleDeleteDesign} 
          />
        ))}
      </div>
    </div>
  );
};

export default CommunityFeed;