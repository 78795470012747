// src/components/FullScreenImage.js

import React from 'react';
import { FaTimes } from 'react-icons/fa'; // Importing close icon

function FullScreenImage({ src, onClose }) {
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
      onClick={onClose}
    >
      {/* Close Button */}
      <button 
        className="absolute top-4 right-4 text-white text-3xl focus:outline-none"
        onClick={onClose}
        aria-label="Close"
      >
        <FaTimes />
      </button>

      <img 
        src={src} 
        alt="Full Screen" 
        className="max-w-full max-h-full object-contain"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking on the image
      />
    </div>
  );
}

export default FullScreenImage;

